import {LocalStorage} from "../../utils/storage";

export const Permissions = {
    GENERAL_SETTINGS: 'general_settings',
    CUSTOMERS: 'customers',
    ALERTS: 'alerts',
    KPI: 'kpi',
    SUPPLIERS: 'suppliers',
    RAW_MATERIAL_SETTINGS: 'raw_material_settings',
    SUPPLY_SETTINGS: 'supply_settings',
    FORMULAS: 'formulas',
    PRODUCTION_SETTINGS: 'production_settings',
    PRODUCT_SETTINGS: 'product_settings',
    NONCONFORMITY: 'nonconformity',
    RAW_MATERIAL_RECEPTION: 'raw_material_reception',
    RAW_MATERIAL_STOCK_DECREASE: 'raw_material_stock_decrease',
    SUPPLY_RECEPTION: 'supply_reception',
    SUPPLY_STOCK_DECREASE: 'supply_stock_decrease',
    PRODUCTIONS: 'productions',
    STARTED_PRODUCTIONS: 'started_productions',
    FINISHED_PRODUCTIONS: 'finished_productions',
    IDENTIFICATION_PRODUCTIONS: 'identification_productions',
    APPCC: 'appcc',
    PRODUCT_DISTRIBUTION: 'product_distribution',
    PRODUCT_STOCK_DECREASE: 'product_stock_decrease',
    RAW_MATERIAL_STOCK: 'raw_material_stock',
    SUPPLY_STOCK: 'supply_stock',
    PRODUCT_STOCK: 'product_stock',
    REPROCESS_STOCK: 'reprocess_stock',
    TRACEABILITY: 'traceability',
    ORGANIZATION: 'organization',
    ROLE_PERMISSIONS: 'roles_permissions',
    CLOUD_STORAGE: 'cloud_storage',
    CUSTOM_FORMS: 'custom_forms',
    CUSTOM_FORM_SETTINGS: 'custom_form_settings'
  }

  export const permissionTypes ={
      WRITE: 'write',
      READ: 'read'
  }

export const hasPermission = (permission, permission_type = null) =>{
    const userPermissions = LocalStorage.get('permissions')
    return userPermissions? userPermissions.some((e) => e.name == permission && (!permission_type || e.permission_type == permission_type)) : false
  }

export const hasAnyPermission = (needed_permissions) => {
    const userPermissions = LocalStorage.get('permissions')? LocalStorage.get('permissions').map((e) => e.name) : []
    return needed_permissions.some(e => {return userPermissions.includes(e)})
}
