import {LocalStorage} from "../../utils/storage";

export const planFeatures = {

    uploadFiles: function() {
      return LocalStorage.existsIn('plan_features', 'upload_files');
    },

    customLogo:function() {
      return LocalStorage.existsIn('plan_features', 'custom_logo');
    } ,
    customPrints: function () {
       return LocalStorage.existsIn('plan_features', 'custom_prints');
    },
    costs: function () {
       return LocalStorage.existsIn('plan_features', 'costs');
    },

}